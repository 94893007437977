.divContainer {
    margin: 50px;
    padding: 50px;
    width: 50vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

canvas {
    position: absolute;
}